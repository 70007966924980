.modal-backdrop {
    background-color: #17372E;

    &.show {
        opacity: 0.85;
    }
}

.modal {

    .modal-content {
        background-color: transparent;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;

        .modal-header {
            border: none;
            padding-left: 0;
            padding-right: 0;
        }

        .modal-body {
            .area-refiliacao {
                margin-top: 62px;
            }


            .title-refiliacao {
                margin-left: 26px;

                font-size: 32px;
                line-height: 40px;
                color: #A6FF00;
                font-family: Ubuntu;
                font-weight: 700;
            }

            .text-refiliacao {
                margin-top: 32px;
                margin-left: 26px;

                font-size: 16px;
                line-height: 24px;
                color: white;
                font-family: Ubuntu;
                font-weight: 400;
            }

            .area-refiliacao {
                // padding: 32px;
            }


            .title-refiliacao {
                font-size: 40px;
                line-height: 48px;
                font-family: Ubuntu;
                font-weight: 700;
            }

            .text-refiliacao {
                font-size: 24px;
                line-height: 32px;
                font-family: Ubuntu;
                font-weight: 400;
            }

            .content-terms {
                padding: 16px;
                color: rgb(53, 62, 75);

                p {
                    padding: 0 30px;
                }
            }

            .btn {
                margin: 16px auto;
            }

            // padding: 18px;

            h1 {
                font-family: 'Arvo';
                font-size: 24px;
                font-weight: bold;
                line-height: 40px;

                color: var(--bs-primary);
            }

            p {

                line-height: 18px;

                a {
                    color: #007bff;
                }
            }


        }

        .modal-footer {
            padding-top: 2rem;
            border: none;
            justify-content: center;
        }


        @include media-breakpoint-down(xl) {

            .modal-body {
                width: 342px;
                height: 418px;
                padding: 0;

                .area-refiliacao {
                    margin-top: 32px;
                }

                .title-refiliacao {
                    margin-top: 32px;
                    margin-left: 14px;

                    font-size: 24px;
                    line-height: 32px;
                    font-family: Ubuntu;
                    font-weight: 700;
                }

                .text-refiliacao {
                    margin-top: 16px;
                    margin-left: 14px;

                    font-size: 16px;
                    line-height: 24px;
                    font-family: Ubuntu;
                    font-weight: 400;
                }
            }


        }

    }

    &.modal-sazonal {

        .modal-header {

            .close {
                margin-bottom: -50px;
                z-index: 99999;
            }
        }

        .modal-body {
            .title {
                color: var(--bs-primary);
                font-family: 'Ubuntu';
                font-style: normal;
                font-weight: 700;
                font-size: 29px;
                line-height: 33px;
                text-align: center;
            }

            .description {
                font-family: 'Ubuntu';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                margin: 0;
            }

            .btn {
                margin: 24px auto;
                line-height: 46px;
            }

            .info {
                font-family: 'Ubuntu';
                font-style: italic;
                display: block;
                font-weight: 300;
                font-size: 12px;
                line-height: 12px;
                text-align: center;
                color: #BCBCBC;
            }
        }

        @include media-breakpoint-down(xl) {

            .modal-header {

                .close {
                    margin-bottom: 0;
                }
            }

            .modal-body {

                .content-terms {
                    text-align: center;
                    background-color: red;
                }

                .title {
                    font-size: 24px;
                    line-height: 28px;
                }

                .description {
                    font-size: 14px;
                    line-height: 24px;
                }

                .info {
                    font-size: 12px;
                    line-height: 12px;
                }

                .title-refiliacao {
                    font-size: 12px;
                    line-height: 12px;
                    font-family: Ubuntu;
                    font-weight: 700;
                }

                .text-refiliacao {
                    font-size: 24px;
                    line-height: 32px;
                    font-family: Ubuntu;
                    font-weight: 400;
                }
            }
        }
    }

    &.modal-sergipe {

        .modal-content {
            align-items: end;
        }

        .modal-header {

            .close {
                margin-bottom: -50px;
                z-index: 99999;
            }
        }

        .modal-body {
            background-color: white;

            .title {
                color: var(--bs-primary);
                font-family: 'Ubuntu';
                font-style: normal;
                font-weight: 700;
                font-size: 29px;
                line-height: 33px;
                text-align: center;
            }

            .description {
                font-family: 'Ubuntu';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                margin: 0;
            }

            .btn {
                margin: 24px auto;
                line-height: 46px;
            }

            .info {
                font-family: 'Ubuntu';
                font-style: italic;
                display: block;
                font-weight: 300;
                font-size: 12px;
                line-height: 12px;
                text-align: center;
                color: #BCBCBC;
            }
        }

        @include media-breakpoint-down(xl) {

            .modal-dialog-centered{
                align-items: flex-start;
            }

            .modal-content {
                align-items: end;
            }

            .modal-header {
                .close {
                    margin-bottom: 0;
                }
            }

            .modal-body {
                .content {
                    background-color: white;
                }

                .content-terms {
                    text-align: center;
                    background-color: white;
                    margin: 12px;
                }

                .title {
                    font-size: 24px;
                    line-height: 28px;
                }

                .description {
                    font-size: 14px;
                    line-height: 24px;
                }

                .info {
                    font-size: 12px;
                    line-height: 12px;
                }

                .title-refiliacao {
                    font-size: 12px;
                    line-height: 12px;
                    font-family: Ubuntu;
                    font-weight: 700;
                }

                .text-refiliacao {
                    font-size: 24px;
                    line-height: 32px;
                    font-family: Ubuntu;
                    font-weight: 400;
                }
            }
        }
    }

    &.modal-content {

        .modal-content {
            align-items: end;
        }

        .modal-header {

            .close {
                margin-bottom: -50px;
                z-index: 99999;
            }
        }

        .modal-body {
            background-color: white;

            .title {
                color: var(--bs-primary);
                font-family: 'Ubuntu';
                font-style: normal;
                font-weight: 700;
                font-size: 29px;
                line-height: 33px;
                text-align: center;
            }

            .description {
                font-family: 'Ubuntu';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                margin: 0;
            }

            .btn {
                margin: 24px auto;
                line-height: 46px;
            }

            .info {
                font-family: 'Ubuntu';
                font-style: italic;
                display: block;
                font-weight: 300;
                font-size: 12px;
                line-height: 12px;
                text-align: center;
                color: #BCBCBC;
            }
        }

        @include media-breakpoint-down(xl) {

            .modal-dialog-centered{
                align-items: flex-start;
            }

            .modal-content {
                align-items: end;
            }

            .modal-header {
                .close {
                    margin-bottom: 0;
                }
            }

            .modal-body {
                .content {
                    background-color: white;
                }

                .content-terms {
                    text-align: center;
                }

                .title {
                    font-size: 24px;
                    line-height: 28px;
                }

                .description {
                    font-size: 14px;
                    line-height: 24px;
                }

                .info {
                    font-size: 12px;
                    line-height: 12px;
                }

                .title-refiliacao {
                    font-size: 12px;
                    line-height: 12px;
                    font-family: Ubuntu;
                    font-weight: 700;
                }

                .text-refiliacao {
                    font-size: 24px;
                    line-height: 32px;
                    font-family: Ubuntu;
                    font-weight: 400;
                }
            }
        }
    }

    &.modal-outubro-rosa {
        @extend .modal-sazonal;

        .modal-dialog {
            max-width: 670px;

            .modal-header {

                .close {
                    margin-bottom: 14px;
                }
            }

            .modal-body {

                .info {
                    font-size: 8px;
                    line-height: 8px;
                }
            }

            @include media-breakpoint-down(xl) {

                max-width: 326px;
                margin-right: auto;
                margin-left: auto;

                .modal-header {

                    .close {
                        margin-bottom: 8px;
                    }
                }

                .modal-body {
                    padding: 9px 9px 4px;

                    >.row {

                        >.col {
                            padding: 0 9px;
                        }
                    }

                    .info {
                        font-size: 9px;
                        line-height: 9px;
                    }
                }
            }
        }
    }

    &.modal-refiliacao {

        .modal-body.default {
            background-color: var(--bs-white);

            border: 4px solid #93D408;
            border-radius: 10px;

            width: 1032px;
            height: 460px;
            padding: 32px;

            h1 {
                margin-top: 62px;
                font-size: 40px;
                line-height: 48px;
                font-family: ubuntu;
            }

            p {
                font-size: 24px;
                line-height: 32px;
                margin-top: 32px;
            }
        }

        .modal-body.blackfriday {
            width: 1040px;
            height: 378px;
            background-image: url('../../../public/todos/refiliacao/background.jpg');

            h1 {
                margin-left: 36px;

                color: #A6FF00;
                font-size: 32px;
                line-height: 40px;
            }

            p {
                margin-top: 32px;
                margin-left: 36px;

                color: white;
                font-size: 16px;
                line-height: 24px;

            }
        }

        @include media-breakpoint-down(xl) {

            .modal-body.default {
                margin-top: -80px;

                border: 4px solid #93D408;
                border-radius: 10px;
                width: 345px;
                height: 550px;
                // padding: 32px;

                h1 {
                    margin-top: 16px;
                    font-size: 24px;
                    line-height: 32px;
                }

                p {
                    margin-top: 16px;

                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                }

            }

            .modal-body.blackfriday {
                background-image: url('../../../public/todos/refiliacao/background-mobile.jpg');
                width: 342px;
                height: 418px;
                padding: 2px 8px;

                h1 {
                    margin-top: 32px;
                    margin-left: 10px;

                    font-size: 24px;
                    line-height: 32px;
                }

                p {
                    margin-top: 16px;
                    margin-left: 10px;

                    font-size: 16px;
                    line-height: 24px;
                    font-weight: 400;
                }

            }
        }
    }

    &.modal-ctablackfriday {

        .modal-body.blackfriday {
            // width: 1032px;
            height: 588px;
            background-image: url('../../../public/todos/cta-blackfriday/background.jpg');
            background-size: cover;

            .content {
                margin-top: 48px;
                margin-left: 84px;

                h1 {
                    color: #A6FF00;
                    font-size: 24px;
                    line-height: 32px;
                }

                p {
                    margin-top: 8px;

                    color: white;
                    font-size: 16px;
                    line-height: 24px;

                }

                .btn-redirect {
                    margin-top: 32px;
                    margin-left: 24px;

                    width: 292px;
                    height: 36px;
                    border-radius: 34px;
                    border: 1px;
                    background: var(--cdt-checkout-a-6-ff-00, #A6FF00);
                    box-shadow: 0px 0px 25px 0px rgba(166, 255, 0, 0.65);

                    font-family: ubuntu;
                    font-weight: 700;
                    color: #1D1D1D;
                }

                .btn-redirectMedium {
                    margin-top: 32px;
                    margin-left: 24px;

                    width: 292px;
                    height: 36px;
                    border-radius: 34px;
                    border: 1px;
                    background: var(--cdt-checkout-a-6-ff-00, #A6FF00);
                    box-shadow: 0px 0px 25px 0px rgba(166, 255, 0, 0.65);

                    font-family: ubuntu;
                    font-weight: 700;
                    color: #1D1D1D;
                }


                .btn-transparent {
                    margin-top: 32px;
                    margin-left: 24px;

                    width: 171px;
                    height: 36px;
                    background: transparent;
                    border-radius: 34px;
                    border: 1px solid var(--cdt-checkout-ffffff, #FFF);

                    font-family: ubuntu;
                    font-weight: 700;
                    color: #FFFFFF;

                }

                .progress-area {
                    margin-top: 43px;
                    margin-left: 30px;

                    a:nth-child(n+2) {
                        margin-left: 5px;
                    }
                }

                .progress-areaTwo {
                    margin-top: 51px;
                    margin-left: 30px;

                    a:nth-child(n+2) {
                        margin-left: 5px;
                    }
                }

                .progress-areaThree {
                    margin-top: 142px;
                    margin-left: 30px;

                    a:nth-child(n+2) {
                        margin-left: 5px;
                    }
                }

            }

            .image {
                margin-top: 51px;
                margin-right: 160px;
            }

            .image-second {
                margin-right: 112px;
            }

            .image-three {
                margin-right: 112px;
                margin-top: -13px;
            }


        }

        @include media-breakpoint-down(xl) {

            .modal-body.blackfriday {
                height: 588px;
                background-image: url('../../../public/todos/cta-blackfriday/background-mobile.jpg');
                background-size: cover;

                .content {
                    margin-top: 0px;
                    margin-left: 0px;

                    .fade-in {
                        opacity: 0;
                        transition: opacity 1s ease-in-out;
                    }

                    .fade-in.active {
                        opacity: 1;
                    }

                    h1 {
                        color: #A6FF00;
                        font-size: 24px;
                        line-height: 32px;

                        text-align: center;
                    }

                    p {
                        margin-top: 8px;
                        margin-left: 8px;

                        color: white;
                        font-size: 16px;
                        line-height: 24px;

                        text-align: center;
                    }

                    .btn-container {
                        margin-top: -14px;
                        margin-left: -10px;

                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .btn-redirect {
                            width: 216px;
                            height: 36px;
                            border-radius: 34px;
                            border: 1px;
                            background: var(--cdt-checkout-a-6-ff-00, #A6FF00);
                            box-shadow: 0px 0px 25px 0px rgba(166, 255, 0, 0.65);

                            font-family: ubuntu;
                            font-weight: 700;
                            color: #1D1D1D;
                        }

                    }

                    .btn-redirectMedium {
                        margin-top: 32px;
                        margin-left: 84px;

                        width: 177px;
                        height: 36px;
                        border-radius: 34px;
                        border: 1px;
                        background: var(--cdt-checkout-a-6-ff-00, #A6FF00);
                        box-shadow: 0px 0px 25px 0px rgba(166, 255, 0, 0.65);

                        font-family: ubuntu;
                        font-weight: 700;
                        color: #1D1D1D;
                    }

                    .btn-transparent {
                        margin-top: 60px;
                        margin-left: 23px;

                        width: 296px;
                        height: 36px;
                        background: transparent;
                        border-radius: 34px;
                        border: 1px solid var(--cdt-checkout-ffffff, #FFF);

                        font-family: ubuntu;
                        font-weight: 700;
                        color: #FFFFFF;

                    }


                    .progress-area {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        margin-top: 33px;
                        margin-left: 0px;

                        a:nth-child(n+2) {
                            margin-left: 5px;
                        }
                    }

                    .progress-areaTwo {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        margin-top: 34px;
                        margin-left: 0px;

                        a:nth-child(n+2) {
                            margin-left: 5px;
                        }
                    }

                    .progress-areaThree {
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        margin-top: 130px;
                        margin-left: 0px;

                        a:nth-child(n+2) {
                            margin-left: 5px;
                        }
                    }

                }

                .image {
                    margin-left: 105px;
                    margin-top: -37px;
                    margin-right: 0;
                }

                .image-second {
                    margin-left: 105px;
                    margin-top: -30px;
                    margin-right: 0;
                }

                .image-three {
                    margin-left: 105px;
                    margin-top: -44px;
                    margin-right: 0;
                }

            }
        }
    }

}