.card-container {
    display: flex;
    margin-top: 40px;

    @include media-breakpoint-down(xl) {
        // display: none;
        margin-top: 0px;

    }
}

.card-plans {
    border: 4px solid var(--bs-border-price);
    border-radius: 10px;
    padding: 19px 18px;
    // margin: 31px auto 19px;
    align-items: center;
    width: 196px;
    height: 157px;

    // .title {
    //     font-weight: bold;
    //     font-size: 24px;
    //     line-height: 40px;
    //     font-family: arvo;
    // }
    
    .blackfriday {
        width: 115px;
        height: 3px;
        background: #1A4438;
        margin-top: 43px;
        margin-left: 33px;
        position: absolute;
        z-index: 1;
    }


    @include media-breakpoint-down(xl) {
        // margin: 16px auto 19px;

        // width: 149px;
        // height: 122px;

        margin: 16px auto 19px;

        width: 169px;
        height: 122px;
    }


    h5 {
        font-size: 16px;
        line-height: 18px;
        position: absolute;
        top: -18px;
        width: 160px;
        background-color: white;
        color: var(--bs-text-price);
        text-align: center;
        margin: 0 auto;

        font-family: Lato;
        font-weight: 900;
    }

    .currency {
        font-size: 26px;
        line-height: 20px;
        font-weight: bold;
        color: var(--bs-text-price);
        display: flex;
        padding-top: 12px;
        padding-bottom: 12px;
        align-items: center;
        font-family: arvo;
    }

    .value {
        color: var(--bs-text-price);
        font-size: 84px;
        line-height: 94px;
        margin-bottom: 0px;
        position: relative;

        font-family: arvo;
        font-weight: 700;
    }

    .value {
        color: var(--bs-text-price);
        font-size: 84px;
        line-height: 94px;
        margin-bottom: 0px;
        position: relative;

        font-family: arvo;
        font-weight: 700;
    }

    .description {
        color: var(--bs-primary);
        font-size: 12px;
        font-weight: bold;
        line-height: 14px;
        margin-bottom: 0;

        font-family: Lato;
        font-weight: 900;

    }

    @include media-breakpoint-down(xl) {
        h5 {
            font-size: 12px;
        }

        .currency {
            font-size: 20px;
        }

        .value {
            font-size: 64px;
        }

        .description {
            font-size: 9px;
            margin-top: -16px;
        }
    }
}

.card-plans-larger {
    border: 4px solid var(--bs-border-price);
    border-radius: 10px;
    padding: 6px 16px;
    // margin: 31px auto 0;
    align-items: center;
    width: 230px;
    height: 157px;


    @include media-breakpoint-down(xl) {
        // margin: 16px auto 19px;

        width: 169px;
        height: 124px;
    }


    h5 {
        font-size: 16px;
        line-height: 18px;
        position: absolute;
        top: -11px;
        width: 141px;
        background-color: white;
        color: var(--bs-text-price);
        text-align: center;
        margin: 0 auto;
        font-family: Lato;
        font-weight: 900;
    }

    p {
        font-size: 14px;

    }

    .title {
        font-size: 14px;
        line-height: 16px;
        color: var(--bs-primary);
        display: flex;
        // padding-top: 12px;
        // padding-bottom: 12px;
        align-items: center;
        font-family: Lato;
        font-weight: 900;

    }

    .currency {
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: var(--bs-text-price);
        display: flex;
        // margin-left: 22px;
        align-items: center;
        font-family: arvo;
        font-weight: 700;

    }

    .value {
        color: var(--bs-text-price);
        font-size: 55px;
        line-height: 70px;
        // margin-bottom: 0px;
        // position: relative;
    }

    .description {
        color: var(--bs-primary);
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 0;
        font-family: Lato;
        font-weight: 400;

    }


    @include media-breakpoint-down(xl) {
        h5 {
            font-size: 12px;
        }

        .currency {
            font-size: 12px;
        }

        .value {
            font-size: 42px;
        }

        .description {
            font-size: 9px;
        }

        .title {
            font-size: 10px;
        }


    }


}

.card-plans-2 {
    border: 4px solid var(--bs-border-price);
    border-radius: 10px;
    padding: 8px 16px;
    // margin: 31px auto 0;
    align-items: center;
    width: 230px;
    height: 157px;


    @include media-breakpoint-down(xl) {
        // margin: -24px auto 19px;
        margin-right: 10px;
        margin-top: -41px;

        width: 162px;
        height: 123px;
    }


    h5 {
        font-size: 16px;
        line-height: 18px;
        position: absolute;
        top: -11px;
        width: 141px;
        background-color: white;
        color: var(--bs-text-price);
        text-align: center;
        margin: 0 auto;
        font-family: Lato;
        font-weight: 900;
    }

    p {
        font-size: 12px;

    }

    .title {
        font-size: 14px;
        line-height: 16px;
        color: var(--bs-primary);
        display: flex;
        // padding-top: 12px;
        // padding-bottom: 12px;
        align-items: center;
        font-family: Lato;
        font-weight: 900;

    }

    .currency {
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: var(--bs-text-price);
        display: flex;
        // margin-left: 22px;
        align-items: center;
        font-family: arvo;
        font-weight: 700;

    }

    .value {
        color: var(--bs-text-price);
        font-size: 55px;
        line-height: 70px;
        // margin-bottom: 0px;
        // position: relative;
    }


    .blackfriday {
        width: 99px;
        height: 3px;
        background: #1A4438;
        margin-top: 37px;
        margin-left: 27px;
        position: absolute;
    }


    .description {
        color: var(--bs-primary);
        font-size: 12px;
        line-height: 14px;
        margin-bottom: 0;
        font-family: Lato;
        font-weight: 400;

    }


    @include media-breakpoint-down(xl) {
        h5 {
            font-size: 12px;
            top: -22px;
            line-height: 14px;

        }

        .title {
            font-size: 9px;
        }

        .currency {
            font-size: 20px;
        }

        .value {
            font-size: 64px;
        }

        .description {
            font-size: 9px;
            margin-top: -16px;
        }

        .text {
            font-size: 12px;
        }
    }


}


.card-payment-method {
    display: flex;
    align-items: center;
    padding: 25px 30px;
    min-height: 83px;
    background: var(--bs-white);
    border: 1px solid var(--bs-card-payment-border);
    box-sizing: border-box;
    border-radius: 10px;

    font-size: 16px;
    line-height: 18px;
    color: var(--bs-card-payment-text);
    cursor: pointer;
}

.card-payment-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--bs-white);
    max-width: 350px;
    border-radius: 10px;
    padding: 16px 70px;
    margin: 0 auto 24px;

    h6 {
        font-weight: 300;
        font-size: 14px;
        margin-bottom: 0;
        line-height: 22px;
    }

    h1 {
        font-weight: bold;
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 0;
    }
}

.card-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    border: 1px solid var(--bs-border-active);
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
}