.voucher {

    legend {
        font-size: 10px;
        font-weight: 900;
        font-family: Lato;
        line-height: 24px;
        color: var(--bs-label-field);
        background-color: var(--bs-white);

        margin-bottom: -12px;
        margin-left: 62px;
        width: auto;
        padding-left: 4px;
        padding-right: 4px;
    }

   

    .voucher-border {
        border: 1px solid var(--bs-primary);
        border-radius: 14px;
        padding-right: 15px;
    }

    .voucher-detail {
        margin-top: 12px;
        line-height: 12px;
        color: var(--bs-border-error);
    }

    .input-group {

        img {
            width: 40px;
        }

        // .form-control {
        //     margin-left: 0;
        //     border-top-left-radius: "10px";
        //     border-bottom-left-radius: "10px";

        //     &:invalid {
        //         border-color: var(--bs-border-error);
        //         background-color: red;
        //     }
        // }
    }

}

.input-group> :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-radius: 10px;
}