$primary: #02A785;
$secondary: #93D408;
$success: #198754;
$info: #B7B7B7;
$warning: #fd7e14;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;
$bgFooter: #F8F8F8;
$bgCarrousel: #4BA68A;
$textCarrousel: #1D1D1D;
$arrowCarrousel: white;
$bgButton: #93D408;
$textButton: #FFFFFF;
$hoverButton: #93D408;
$bg0800: #00C79E;
$text0800: #FCF8EF;
$hover0800: #00C79E;
$textPrice: #93D408;
$borderPrice: #93D408;
$textDefault: #1D1D1D;
$textInfo: #7F8690;
$textLink: #00A988;
$icons: #7F8690;
$separatorVertical: #E0E0E0;
$separatorHorizontal: #F2F2F2;
$cardPaymentBorder: #F2F2F2;
$cardPaymentText: #4F4F4F;
$labelField: #1D1D1D;
$bgField: #FCF8EF;
$borderField: transparent;
$borderActive: #C4C4C4;
$borderError: #F00000;
$borderSuccess: #4BA68A;
$iconsSuccess: #A6FF00;
$linkTerms: #0089ff;
$voucherBorder: #4BA68A;
$bgCardRegister: #4BA68A;
$bgButtonDependent: #275E4E;
$textButtonDependent: #FFFFFF;
$bgButtonDependentRemove: #FCF8EF;
$textButtonDependentRemove: #F00000;
