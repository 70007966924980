@font-face {
    font-family: 'panton-extrabold';
    src: url("../../assets/fonts/_panton-extrabold-webfont.woff2") format('woff2'),
        url("../../assets/fonts/_panton-extrabold-webfont.woff") format('woff');
    font-weight: normal;
    font-style: normal;
}

* {
    font-feature-settings: "kern" 1;
    font-kerning: normal;
    text-rendering: optimizeLegibility;
    letter-spacing: 0.015em;
    word-spacing: 0.001em;
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: Ubuntu, Source Sans Pro, sans-serif;
    overflow: hidden;
}

a {
    color: var(--bs-text-link);
    text-decoration: none;

    &:hover {
        color: var(--bs-text-link);
    }

    &.underline {
        text-decoration: underline;
    }
}

* {
    box-sizing: border-box;
}


button {

    &.text-link-terms {
        background: none;
        border: none;
        margin: 0;
        padding: 0;
    }
}

.font-panton-extrabold{
    font-family: panton-extrabold;
    font-weight:700;
}

// utilities
.homolog-message {
    width: 100%;

    @include media-breakpoint-down(md) {
        position: fixed;
        top: 0;
    }
}

.navbar {
    max-width: 820px;
    margin-left: auto;

    .logo {
        cursor: pointer;
        height: 53px;
        margin-top: 10px;
    }

    .btn-sm {
        width: 256px;
        height: 62px;
        font-size: 22px;
        margin-top: 10px;
        font-weight: 900;
        font-family: Lato;
    }

    @include media-breakpoint-down(sm) {

        .container-navbar {
            padding: 0 30px;
        }


        &.fixed-top {
            background-color: transparent;
            position: fixed !important;
            top: 6px;
        }

    }


    @include media-breakpoint-down(xl) {

        .container-navbar {
            padding: 0 40px;
            align-items: start;
        }

        &.fixed-top {
            background-color: transparent;
        }

        .logo {
            height: 26px;
        }

    }

    @include media-breakpoint-down(xl) {
        .btn-sm {
            padding: 10px 13px;
            // margin: 50px 10px 20px 50px;

            width: 145px;
            height: 35px;
            font-size: 12px;

        }
    }
}

.pointer {
    cursor: pointer;
}

.text-muted {
    color: var(--bs-text-info);
}

hr {
    opacity: 1;
    background-color: var(--bs-separator-horizontal);
}

.vr {
    opacity: 1;
    background-color: var(--bs-separator-vertical);
}

//Background Right Pages
.bg-right-page {
    position: absolute;
    top: 0;
    left: calc(var(--bs-gutter-x) * 0.5);
    height: 100%;
    width: auto;
    z-index: 0;

    @include media-breakpoint-down(xl) {
        display: none;
    }
}

.page-use-banner {

    .navbar {
        z-index: 10;
    }

    .app-container {
        margin-top: -82px;
    }

    @include media-breakpoint-up(xl) {
        background: linear-gradient(to right, var(--bs-white) 65%, var(--bs-primary) 35%);
    }

    @include media-breakpoint-down(xl) {
        .app-container {
            margin-top: -75px;
        }
    }
}

.app-container {

    .message-col {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100vh;

        .credit-card-view {
            margin-top: 150px;

            p {
                font-size: 14px;
                color: rgb(53, 62, 75);
                text-align: center;
                line-height: 18px;
                font-weight: 400;
                // font-family: Lato;
            }

            button {
                width: 156px;
                height: 156px;
                border-radius: 10px;
                background-color: var(--bs-bg-field);
                border: 3px solid transparent;
                transition: border-color 0.3s ease 0s;

                &:hover {
                    border-color: var(--bs-primary);
                }
            }

            strong {
                font-size: 10px;
                text-transform: uppercase;
                line-height: 15px;
                margin: 15px 0px 10px;
                font-weight: 800;
                font-family: Lato;
                color: rgb(53, 62, 75);
            }

            span {
                font-weight: 400;
                font-family: Lato;
                font-size: 10px;
            }

        }
    }

    .message-col-expansive {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100vh;
        margin-top: 70px;
        background: white;
        z-index: 1;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        position: fixed;

        .credit-card-view {
            margin-top: 150px;

            p {
                font-size: 14px;
                color: rgb(53, 62, 75);
                text-align: center;
                line-height: 18px;
                font-weight: 400;
                // font-family: Lato;
            }

            button {
                width: 156px;
                height: 156px;
                border-radius: 10px;
                background-color: var(--bs-bg-field);
                border: 3px solid transparent;
                transition: border-color 0.3s ease 0s;

                &:hover {
                    border-color: var(--bs-primary);
                }
            }

            strong {
                font-size: 10px;
                text-transform: uppercase;
                line-height: 15px;
                margin: 15px 0px 10px;
                font-weight: 800;
                font-family: Lato;
                color: rgb(53, 62, 75);
            }

            span {
                font-weight: 400;
                font-family: Lato;
                font-size: 10px;
            }

        }
    }

    .message-col-fixed {
        overflow-y: auto;
        overflow-x: hidden;
        height: 100vh;

        .credit-card-view {
            margin-top: 150px;

            p {
                font-size: 14px;
                color: rgb(53, 62, 75);
                text-align: center;
                line-height: 18px;
                font-weight: 400;
                // font-family: Lato;
            }

            button {
                width: 156px;
                height: 156px;
                border-radius: 10px;
                background-color: var(--bs-bg-field);
                border: 3px solid transparent;
                transition: border-color 0.3s ease 0s;

                &:hover {
                    border-color: var(--bs-primary);
                }
            }

            strong {
                font-size: 10px;
                text-transform: uppercase;
                line-height: 15px;
                margin: 15px 0px 10px;
                font-weight: 800;
                font-family: Lato;
                color: rgb(53, 62, 75);
            }

            span {
                font-weight: 400;
                font-family: Lato;
                font-size: 10px;
            }

            @include media-breakpoint-down(xl) {
                background: white;

                .message-col {
                    background: white;
                    border-top-left-radius: 20px;
                    border-top-right-radius: 20px;

                    .message-col-fixed {
                        margin-top: 70px;
                        position: fixed;
                        background: white;
                        border-top-left-radius: 20px;
                        border-top-right-radius: 20px;
                    }

                    .credit-card-view {
                        margin-top: 30px;
                    }
                }

                &.page-success {
                    margin-top: -90px;
                }

                .img-card {
                    max-height: 140px;
                    margin: 0 auto;
                }

                h6 {
                    font-size: 16px;
                    line-height: 24px;
                }

                .space {
                    display: none;
                }

                .content-start {
                    max-width: initial;

                    .button-start {
                        position: relative;
                        left: 0;
                        right: 0;
                        background: var(--bs-white);
                        padding: 24px 0;
                        z-index: 1000;
                        width: 100%;
                        height: auto;

                        button {
                            height: 62px;
                            // filter: drop-shadow(0px 6px 20px rgba(234, 84, 0, 0.2))
                        }
                    }
                }
            }

        }

        @include media-breakpoint-down(xl) {
            margin-top: 70px;
            position: fixed;
            background: white;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }
    }

    .content-start {
        max-width: 515px;

        small {
            font-size: 14px;
            line-height: 20px;
            font-weight: 400;
            font-family: Lato;

        }

        .success-message {
            margin-top: 300px;

            p {
                font-size: 0.88rem;
                line-height: 18px;
            }

            .code-transation {
                background-color: #FCF8EF;

                font-size: 1rem;
            }

            .description {
                font-size: 14px;
                font-family: 'Ubuntu';
                color: #878787;
                text-decoration: underline;
            }
        }

        .img-analise {
            width: 100%;
            height: 164px;

            @include media-breakpoint-down(md) {
                width: 50%;
            }
        }

        .analise-col {
            padding: 48px;

            p {
                font-size: 16px;
                line-height: 26px;
                font-weight: 400;
                font-family: Lato;
            }

            @include media-breakpoint-down(md) {
                padding: 0;
            }
        }

        @include media-breakpoint-down(xl) {

            .success-message {
                margin-top: 230px;
                padding: 20px;

                p {
                    font-size: 80%;
                    line-height: 18px;
                }

                .code-transation {
                    background-color: #FCF8EF;
                    font-size: 16px;
                }

                .description {
                    font-size: 14px;
                    font-family: 'Ubuntu';
                    color: #878787;
                    text-decoration: underline;
                }
            }
        }

    }

    @include media-breakpoint-down(xl) {
        background: #02A785;

        .content-app{
            overflow: auto;
        }

        .message-col {
            background: white;
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;

            .message-col-fixed {
                margin-top: 70px;
                position: fixed;
                background: white;
                border-top-left-radius: 20px;
                border-top-right-radius: 20px;
            }

            .credit-card-view {
                margin-top: 30px;
            }
        }

        &.page-success {
            margin-top: -90px;
        }

        .img-card {
            max-height: 140px;
            margin: 0 auto;
        }

        h6 {
            font-size: 16px;
            line-height: 24px;
        }

        .space {
            display: none;
        }

        .content-start {
            max-width: initial;

            .button-start {
                position: relative;
                left: 0;
                right: 0;
                background: var(--bs-white);
                padding: 24px 0;
                z-index: 1000;
                width: 100%;
                height: auto;

                button {
                    height: 62px;
                    // filter: drop-shadow(0px 6px 20px rgba(234, 84, 0, 0.2))
                }
            }
        }
    }
}

.content-start-small {
    margin-top: 58px;
    width: 422px;
    height: 100vh;

    p {
        font-size: 14px;
        line-height: 18px;
        font-family: Lato;
    }

}

.icon-form {
    margin: 37px 28px 8px 0;

    @include media-breakpoint-down(xl) {
        margin-right: 15px;
    }
}

.banner-col {
    position: relative;
    width: 55%;

    .img-card {
        max-height: 600px;
        object-fit: initial;
        z-index: 2;
        margin-left: 46px;
    }

    @include media-breakpoint-down(xl) {
        position: relative;
        width: 100%;

        h1 {
            font-size: 18px;
            line-height: 24px;
            margin-top: 77px;
        }
    }
}

p {
    font-size: 14px;
    line-height: 24px;
    color: var(--bs-text-default);
}

small {
    font-size: 10px;
    line-height: 14px;
    display: inherit;
    color: var(--bs-text-default);
}

.carousel-fade {
    .carousel-inner {
        .carousel-item {
            transition: opacity 500ms;
        }
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.title-carousel {
    min-height: 120px;
    display: flex;
    align-items: center;

    &.active {
        animation: fadeIn 1s alternate;
    }

    @include media-breakpoint-down(xl) {
        min-height: initial;
    }
}

.help-text {

    p,
    a {
        font-size: 14px;

        &:not(.btn) {
            line-height: 20px;
        }
    }
}

.footer-content {
    @include media-breakpoint-down(xl) {
        margin: 0px -22px;
        padding: 4px 0;
    }

}

.footer {
    max-height: 34px;

    &.bg-footer {
        background-color: var(--bs-bg-footer);
    }

    p {
        margin: 0;
        font-size: 12px;
        padding-bottom: 6px;
        padding-top: 6px;
    }

    @include media-breakpoint-down(xl) {

        p {
            background-color: var(--bs-bg-footer);
            font-size: 11px;
            color: #919191 !important;
            margin: 0px -12px;
            padding: 4px 0;
        }
    }
}

.use-banner-footer {

    &.bg-footer {
        background-color: var(--bs-bg-footer);
    }

    @include media-breakpoint-down(xl) {
        margin-top: 30px;


        &.inicio {
            margin-bottom: 100px;
        }
    }

}

.fill-primary {
    fill: var(--bs-primary);
}

::-webkit-scrollbar-track {
    background: var(--bs-white);
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--bs-border-price);
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--bs-border-price);
}

/* Hide Recaptcha */
.grecaptcha-badge {
    display: none !important;
}

.content-progress {

    a {
        font-size: 14px;
        display: inline-block;
    }

    span {
        font-size: 12px;
        display: inline-block;
    }

    .circle {
        width: 24px;
        height: 24px;
        border: 2px solid var(--bs-primary);
        border-radius: 100%;
        text-align: center;
        margin-right: 4px;
        color: black;
    }

}

.content-progress-space {
    margin-top: 100px;

    a {
        font-size: 14px;
        display: inline-block;
    }

    span {
        font-size: 12px;
        display: inline-block;
    }

    .circle {
        width: 24px;
        height: 24px;
        border: 2px solid var(--bs-primary);
        border-radius: 100%;
        text-align: center;
        margin-right: 4px;
        color: black;
    }

    @include media-breakpoint-down(xl) {
        margin-top: 30px;
    }

}

.creditcard-content {

    .logocard {
        width: 25.625rem;
    }

    .creditcard {
        text-shadow: 0px 1px 1px #0000009A;

        .creditcardDetails {
            width: 23.125rem;
        }

        span {
            color: #C0DF88;
            font-size: 0.75rem;
            font-family: 'Ubuntu';
            text-transform: uppercase;
            font-weight: bold;
            line-height: 4px;
        }

        h1 {
            color: #FFFFFF;
            font-size: 1rem;
            font-family: 'Ubuntu';
            font-weight: bold;
        }
    }

    @include media-breakpoint-down(xl) {
        .logocard {
            width: 19.625rem;
        }

        .logo {
            width: 5rem;
        }

        .creditcard {
            text-shadow: 0px 1px 1px #0000009A;

            .creditcardDetails {
                width: 18.125rem;
            }

            span {
                // font-size: 87.5%;
                font-size: 0.47rem;
            }

            h1 {
                font-size: 0.75rem;
            }
        }

    }
}