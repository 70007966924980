.carousel-content {
    width: 100%;
    height: 100%;
    max-width: 820px;
    margin-left: auto;
    // max-height:200px;

    .carousel-inner {

        display: flex;
        align-items: center;
        align-content: center;
        height: 100vh;
        // max-height: 200px;

        .content {
            margin: 45px 0px 21px;
        }

        strong {
            max-width: 455px;
            font-size: 60px;
            line-height: 64px;

            font-family: ubuntu;
            font-weight: 700;
        }

        .area-description {
            // margin: 10px 179px;

            p {
                font-family: Lato;
                font-weight: 400;
                font-size: 22px;
                line-height: 26px;

            }

            .area-description-custom {
                padding: 0 87px;
                margin-top: 30px;
            }
        }

        @include media-breakpoint-down(xl) {
            padding: 0;
            position: sticky;

            // max-height: 337px;
            // margin-top: 60px;
            // margin-bottom: 30px;

            max-height: 295px;
            margin-top: 62px;
            margin-bottom: 14px;

            .content {
                // margin: 5px 53px -27px;
                margin: 5px 3px -27px;
                position: sticky;

                .description {
                    margin: 0 54px 0;
                }

                .area-description-custom {
                    margin: 5px 39px -27px;
                    padding: 0;
                }

            }

            strong {
                font-size: 30px;
                line-height: 32px;
                max-width: 240px;
                margin: 22px 0px 10px;
            }

            .area-description {
                p {
                    font-size: 14px;
                    line-height: 18px;
                }
            }


        }

        @include media-breakpoint-up(xl) {

            .area-description {
                margin: 10px 94px;

                p {
                    font-family: Lato;
                    font-weight: 400;
                    font-size: 22px;
                    line-height: 26px;

                }
            }
        }

        .carousel-item {

            img {
                object-fit: cover;
                width: initial;
                height: auto;

                @include media-breakpoint-down(xl) {}

            }

            &.fullbanner {

                img {
                    height: 100vh;
                    max-width: 100%;
                    max-height: 100%;
                    object-fit: cover;

                    // max-height: 432px;
                    // object-fit: contain;

                    @include media-breakpoint-down(xl) {
                        // width: 265px;
                        // max-height: 600px;
                        object-fit: contain;

                    }
                }
            }
        }

    }


    .carousel-control-prev,
    .carousel-control-next {
        opacity: 1;

        @include media-breakpoint-down(xl) {
            height: 500px;
        }
    }

    .carousel-control-prev-icon,
    .carousel-control-next-icon {
        background-image: url("../../../public/todos/carousel/arrow-right.svg");
        width: 42px;
        height: 42px;

        @include media-breakpoint-down(xl) {
            width: 12px;
            height: 32px;
        }
    }

    .carousel-control-prev-icon {
        transform: rotate(180deg);
    }

}