// Inputs
.form-control {
    min-height: 52px;
    padding-left: 20px;
    top: calc(50% - 51px/2);
    background: var(--bs-bg-field);
    border-radius: 10px;
    border-color: var(--bs-border-field);
    font-size: 16px;
    line-height: 24px;

    &-focus {
        background: var(--bs-bg-field);
        border: 1px solid var(--bs-border-active);
        box-shadow: none;
    }

    &:focus {
        background: var(--bs-bg-field);
        border: 1px solid var(--bs-border-active);
        box-shadow: none;
    }

    &:disabled {
        background-color: var(--bs-gray-200);
        border-color: var(--bs-border-field);
        cursor: not-allowed;
    }

    &::placeholder {
        min-height: 24px;
        top: calc(50% - 24px/2 + 0.5px);
        font-style: italic;
        font-size: 16px;
        line-height: 24px;
        color: var(--bs-text-info);
        font-weight: 300;
        font-family: Lato;
    }

    &.is-invalid {
        border-color: var(--bs-border-error);
        background-image: none;

        &:focus {
            background: var(--bs-bg-field);
            box-shadow: none;
        }
    }

    &.is-valid {
        border-color: var(--bs-border-success);
        background-image: none;

        &:focus {
            background: var(--bs-bg-field);
            box-shadow: none;
        }
    }

    @include media-breakpoint-down(lg) {
        font-size: 14px;
        padding: 0 12px;
    }
}

.form-group {
    position: relative;

    .invalid-feedback {
        position: absolute;
        bottom: -20px;
        right: 0;
        margin-top: 8px;
        font-size: 12px;
        line-height: 12px;
        color: var(--bs-border-error);
        text-align: right;
    }
    
    .form-text {
        position: absolute;
        left: 0;
        margin-top: 8px;
        font-size: 12px;
        line-height: 12px;
        color: var(--bs-success);
        text-align: left;
    }
}

.invalid-feedback {
    margin-top: 8px;
    font-size: 12px;
    line-height: 12px;
    color: var(--bs-border-error);
    text-align: right;
}

.form-label,
label {
    text-transform: uppercase;
    font-size: 10px;
    font-weight: 900;
    color: var(--bs-label-field);
    margin-bottom: 10px;
    font-family: Lato;
}

.form-check {
    margin-bottom: 0.325rem;

    .form-check-label {
        text-transform: none;
        font-weight: 400;
        padding-left: 0;
        margin-bottom: 0;
        padding-top: 5px;
        font-size: 14px;

        b,
        strong {
            font-weight: 900;
        }

        button {
            b,
            strong {
                text-decoration: underline;
            }
        }
    }
}


.form-text {
    font-size: 12px;
    line-height: 24px;
    color: var(--bs-label-field);
}

.was-validated .form-control:valid {
    background-image: none;
    box-shadow: none;

    &:focus {
        box-shadow: none;
    }
}

.was-validated .form-control:invalid {
    background-image: none;
    box-shadow: none;

    &:focus {
        box-shadow: none;
    }
}

.input-group-text {
    background-color: transparent;
    border: none;
}

.form-check-label {
    font-size: 12px;
    line-height: 20px;
    color: var(--bs-label-field);
}

.form-check-input {
    border-color: var(--bs-primary);
    border-width: 2px;
    margin-top: 6px;
    width: 20px;
    height: 20px;

    &:focus {
        border-color: var(--bs-primary);
        box-shadow: none;
    }

    &.is-invalid {
        &:checked {
            background-color: transparent;
        }
    }

    &:checked {
        border-color: var(--bs-primary);
        background-color: transparent;

        &[type=checkbox] {
            position: relative;
            display: inline-block;
            width: 20px;
            height: 20px;
            //background-image: url('../../assets/icons/icon-check.svg');
            background-image: none;

            &::before {
                position: absolute;
                left: -2px;
                top: 20%;
                width: 3px;
                height: 60%;
                background-color: var(--bs-primary);
                content: "";
                transform: translateX(10px) rotate(-45deg);
                transform-origin: left bottom;
            }

            &::after {
                position: absolute;
                left: -2px;
                bottom: 3px;
                width: 125%;
                height: 2px;
                background-color: var(--bs-primary);
                content: "";
                transform: translateX(10px) rotate(-45deg);
                transform-origin: left bottom;
            }
        }
    }
}

.spinner-class{
    top: 43px;
    right: 8px;
}

.input-error {
    background-color: var(--bs-border-error);
    width: 20px;
    height: 20px;
    border-radius: 100%;
    text-align: center;
    color: #fff;
    position: absolute;
    right: 10px;
    top: 47px;
    font-size: 14px;
}

.button-copytoclipboard{
    top: 43px;
    right: 8px;
    text-decoration: none;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4px 16px;
    gap: 10px;
    background: #E8E3DA;
    border-radius: 4px;
    min-height: 32px;
    border: none;
    box-shadow: none;

    &:hover,
    &:focus,
    &:active,
    &:active:focus {
        background: #E8E3DA;
        border: none;
        box-shadow: none;
    }

    &.copy-successfully {
        background: var(--bs-neon);
    }

    &:before {
        content: "";
        background: var(--bs-yellow);
        width: 10px;
        height: 32px;
        position: absolute;
        left: -10px;
    }

    .text {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 24px;
        color: var(--bs-label-field);
    }
}

.form-check-input.is-invalid ~ .form-check-label {
    color: var(--bs-label-field);
}

.pagamento-form {

    .col.disabled {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--bs-white);
            opacity: 0.5;
            cursor: not-allowed;
        }
    }
}

.form-select {
    font-size: 14px;
    line-height: 24px;
    // background: var(--bs-bg-field);

}

.submit-loader {
    color: var(--bs-primary);
    font-size: 30px;
    text-indent: -9999em;
    overflow: hidden;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    position: relative;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
    animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}
@keyframes load6 {
    0% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    5%,
    95% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
    10%,
    59% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
    }
    20% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
    }
    38% {
        box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
    }
    100% {
        box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
    }
}
@-webkit-keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes round {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}