@import "variables";

@import "~bootstrap/scss/bootstrap";

@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Arvo:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

@import
    "components/common",
    "components/button",
    "components/carousel",
    "components/cards",
    "components/form",
    "components/modal",
    "components/text",
    "components/voucher",
    "components/loading",
    "components/shimmer"
;